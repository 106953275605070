import React from 'react';
import { AppBar, Toolbar, Container, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { MobileMenu } from './MobileMenu';
import { DesktopMenu } from './DesktopMenu';
import { layout } from '../../config/navigation';
import { useMobile } from '../../material/theme';

export const Navbar = props => {
    const location = useLocation();
    const isMobile = useMobile();
    const isDesktop = !isMobile;

    if (location.pathname.startsWith('/extern')) return <div></div>;
    else
        return (
            <AppBar position='static' color='default' elevation={0}>
                <Container maxWidth={false} sx={{ px: isMobile ? "0px !important" : null }}>
                    <Grid container justifyContent='center'>
                        <Grid item xs={12} lg={10} xl={8}>
                            <Toolbar disableGutters>
                                {isMobile && <MobileMenu layout={layout} />}
                                {isDesktop && <DesktopMenu layout={layout} />}
                            </Toolbar>
                        </Grid>
                    </Grid>
                </Container>
            </AppBar>
        );
};

export const NTMMediaIcon = () => {
    return (
        <svg x='0px' y='0px' width='38' height='38' viewBox='0 0 20 20'>
            <path d="M5.062.121V19.41H.436A.438.438,0,0,1,0,18.971V4.716A4.58,4.58,0,0,1,4.564.121Z" transform="translate(0 -0.013)" fill="#1D1E1C" />
            <rect width="5.081" height="19.289" transform="translate(7.038 0.108)" fill="#1D1E1C" />
            <path d="M20.891.561V14.815a4.581,4.581,0,0,1-4.564,4.6h-.5V.121h4.625a.439.439,0,0,1,.437.44" transform="translate(-1.735 -0.013)" fill="#1D1E1C" />
        </svg>
    )
}

export const NTMMediaLogo = () => {
    return (
        <svg width="212.158" height="19.474" viewBox="0 0 212.158 19.474">
            <g id="Group_50" data-name="Group 50" transform="translate(0 0)">
                <g id="Group_1" data-name="Group 1" transform="translate(0 0)">
                    <path id="Path_1" data-name="Path 1" d="M48.549,10.428a28.3,28.3,0,0,1-2.261-2.613,33.91,33.91,0,0,1,.279,3.485v8.009H42.911V.031H43.3L52.875,9.08a27.056,27.056,0,0,1,2.232,2.613s-.251-2.164-.251-3.485V.2h3.656V19.477h-.39Z" transform="translate(-4.703 -0.003)" fill="#1D1E1C" />
                    <path id="Path_2" data-name="Path 2" d="M72.684,3.676H67.269V.22H82.006V3.676H76.592V19.328H72.684Z" transform="translate(-7.373 -0.023)" fill="#1D1E1C" />
                    <path id="Path_3" data-name="Path 3" d="M90.655,0h.307l9.35,10.341L109.523,0h.335V19.305H106.09V12.139c0-1.293.139-3.344.139-3.344a33.751,33.751,0,0,1-2.037,2.7l-3.768,4.243h-.362l-3.768-4.243a33.753,33.753,0,0,1-2.037-2.7s.139,2.051.139,3.344v7.166h-3.74Z" transform="translate(-9.936 0)" fill="#1D1E1C" />
                    <path id="Path_4" data-name="Path 4" d="M130.812,0h.307l9.35,10.341L149.68,0h.335V19.305h-3.767V12.139c0-1.293.139-3.344.139-3.344a33.749,33.749,0,0,1-2.037,2.7l-3.768,4.243h-.362l-3.768-4.243a33.754,33.754,0,0,1-2.037-2.7s.139,2.051.139,3.344v7.166h-3.74Z" transform="translate(-14.337 0)" fill="#1D1E1C" />
                    <path id="Path_5" data-name="Path 5" d="M160.436.219H173V3.675h-8.652V8.059h7.619v3.372h-7.619v4.44h9.043v3.456H160.436Z" transform="translate(-17.583 -0.023)" fill="#1D1E1C" />
                    <path id="Path_6" data-name="Path 6" d="M182.662.219H188.5c5.834,0,9.992,3.906,9.992,9.554s-4.131,9.554-9.992,9.554h-5.833ZM188.3,15.871c4.214,0,6.14-2.529,6.14-6.1s-1.926-6.1-6.14-6.1h-1.731v12.2Z" transform="translate(-20.019 -0.023)" fill="#1D1E1C" />
                    <rect id="Rectangle_1" data-name="Rectangle 1" width="3.907" height="19.108" transform="translate(185.057 0.196)" fill="#1D1E1C" />
                    <path id="Path_7" data-name="Path 7" d="M227.12.031h.335l8.681,19.276h-4.1l-1.06-2.584H223.6l-1.06,2.584h-3.768Zm2.457,13.4-1.2-2.895c-.531-1.264-1.088-3.2-1.144-3.4-.028.085-.587,2.08-1.117,3.4l-1.2,2.895Z" transform="translate(-23.977 -0.003)" fill="#1D1E1C" />
                    <path id="Path_8" data-name="Path 8" d="M5.062.121V19.41H.436A.438.438,0,0,1,0,18.971V4.716A4.58,4.58,0,0,1,4.564.121Z" transform="translate(0 -0.013)" fill="#1D1E1C" />
                    <rect id="Rectangle_2" data-name="Rectangle 2" width="5.081" height="19.289" transform="translate(7.038 0.108)" fill="#1D1E1C" />
                    <path id="Path_9" data-name="Path 9" d="M20.891.561V14.815a4.581,4.581,0,0,1-4.564,4.6h-.5V.121h4.625a.439.439,0,0,1,.437.44" transform="translate(-1.735 -0.013)" fill="#1D1E1C" />
                </g>
            </g>
        </svg>
    )
}