import { grey } from '@mui/material/colors';
import InfoIcon from '@mui/icons-material/Info';
import { DefaultTooltip } from './DefaultComponents';

export const CustomInfoIcon = props => {
    const { text, color, sx } = props

    return (
        <DefaultTooltip title={text}>
            <InfoIcon sx={{ ...sx, color: color ?? grey[500] }} />
        </DefaultTooltip>
    );
};