import React from 'react';
import { Chip, Typography, Grid, Box, Card, CardHeader, CardContent, Divider, Stack } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import { PaperBody, DefaultCard } from '../../DefaultComponents';
import { grey, red } from '@mui/material/colors';
import { SiteImage } from '../../SiteImage';
import { SiteBreakDown } from './SiteBreakDown';
import { useMobile } from '../../../material/theme';
import { CustomInfoIcon } from '../../InfoIcon';

export const TargetingGrid = ({ item, combinedReport }) => {
    const isMobile = useMobile();

    return (
        <PaperBody>
            <Typography variant='h5' sx={{ color: grey[600] }}>
                Styrning
            </Typography>
            <Grid container spacing={isMobile ? 2 : 0} justifyContent='space-evenly'>
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard title='Format'>
                        <Format placements={item?.placements} adunits={item?.adunits} />
                    </DefaultCard>
                </Grid>
                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                    <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                </Box>
                <Grid item xs={12} sm={6} lg={3}>
                    <DefaultCard title='Siter'>
                        <SiteChips placements={item?.placements} adunits={item?.adunits} />
                        {!combinedReport && (
                            <Box sx={{ textAlign: "center" }}>
                                <SiteBreakDown item={item} />
                            </Box>
                        )}
                    </DefaultCard>
                </Grid>
                {(item?.placements?.length > 0 && item?.customTargeting?.length > 0) && (
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                        <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                    </Box>
                )}
                {item?.customTargeting?.map((ct, i) => {
                    const isNot = ct?.operator === "IS_NOT";
                    return (
                        <React.Fragment key={ct.key?.id}>
                            <Grid item xs={12} sm={6} lg={3}>
                                <Card variant='outlined' sx={{ textAlign: 'center', border: 'none' }}>
                                    <CardHeader
                                        title={
                                            <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                                                <span>{ct.key?.displayName ?? ct.key?.name ?? ct.key?.id}</span>
                                                {isNot && (
                                                    <CustomInfoIcon
                                                        text="Kampanjen styrs mot besökare som inte täcks av något av följande värden"
                                                        sx={{ pb: 0.25 }}
                                                    />
                                                )}
                                            </Stack>
                                        }
                                        sx={{ textTransform: 'uppercase', color: grey[600] }}
                                    />
                                    <CardContent>
                                        {ct.values.map(v => (
                                            <Chip
                                                key={v?.id}
                                                icon={isNot ? <BlockIcon /> : null}
                                                label={v?.displayName ?? v?.name}
                                                color={isNot ? "secondary" : "primary"}
                                                variant={isNot ? "outlined" : "default"}
                                                sx={{ mx: 0.25, mb: 0.5, "& .MuiChip-icon": { color: isNot ? red[700] : "inherit" } }}
                                            />
                                        ))}
                                    </CardContent>
                                </Card>
                            </Grid>
                            {i !== item?.customTargeting?.length - 1 && (
                                <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                                    <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                                </Box>
                            )}
                        </React.Fragment>
                    );
                })}
            </Grid>
        </PaperBody>
    )
}

const Format = ({ placements, adunits }) => {
    if (placements?.length === 0 && adunits?.length === 0) {
        return <Typography variant='h5' gutterBottom>Inget format</Typography>;
    }

    const uniqueFormats = [...new Set(placements?.map(p => p?.split(/ (.*)/s)[1])), ...new Set(adunits?.map(a => a?.split('-')?.slice(1, 3)?.join(" ")))];
    return uniqueFormats?.map(format => (
        <Chip key={format} label={format} sx={{ mx: 0.25, mb: 0.5 }} color='primary' variant='outlined' />
    ));
};

const SiteChips = ({ placements, adunits }) => {
    if (placements?.length === 0 && adunits?.length === 0) {
        return <Chip label='Ingen site' sx={{ mx: 0.25, mb: 0.5 }} color='secondary' variant='outlined' />;
    }

    const uniqueSites = [...new Set(placements?.map(p => p?.split(' ')[0])), ...new Set(adunits?.map(a => a?.split('-')[0]))];

    if (uniqueSites.length >= 19) {
        return (
            <Chip
                label='Alla sajter'
                sx={{ mx: 0.25, mb: 0.5 }}
                avatar={<SiteImage site='ntm' />}
                variant='outlined'
                color='secondary'
            />
        );
    }

    return uniqueSites?.map(site => {
        return <Chip key={site} label={site} sx={{ mx: 0.25, mb: 0.5 }} avatar={<SiteImage site={site} />} color='secondary' variant='outlined' />
    });
};