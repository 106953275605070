import React from 'react';
import { Grid, ListItem, ListItemText, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/system';

export const DailyBreakDown = ({ data } ) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant='h5' sx={{ color: grey[600] }}>
                    Daglig annonsstatistik
                </Typography>
            </Grid>
            <Grid item xs={12}>
                {data?.length > 0 ? (
                    <ListColumns data={data} />
                ) : (
                    <>
                        <Typography variant='body2'>Daglig annonsstatistik saknas.</Typography>
                    </>
                )}
            </Grid>
        </>
    )
}

function splitArray(arr) {
    if (!arr) return [[], []]
    const length = arr.length;
    const middle = Math.floor(length / 2);

    if (length % 2 === 0) {
        // If the length is even, split the array into two equal parts
        return [arr.slice(0, middle), arr.slice(middle)];
    } else {
        // If the length is odd, make the first array one element larger
        return [arr.slice(0, middle + 1), arr.slice(middle + 1)];
    }
}

const AlternatingListItem = styled(ListItem)(({ theme, index }) => ({
    backgroundColor: index % 2 === 0 ? 'rgba(0, 0, 0, 0.12)' : theme.palette.background.paper,
}));

const ListColumns = ({ data }) => {
    const [a1, a2] = splitArray(data)

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                {a1?.map((item, index) => <Item key={index} data={item} index={index} />)}
            </Grid>
            <Grid item xs={12} sm={6}>
                {a2?.map((item, index) => <Item key={index} data={item} index={index} />)}
            </Grid>
        </Grid>
    );
};

const Item = ({ data, index }) => {
    function getDayOfWeekSwedish(dateString) {
        const daysOfWeek = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];
        const date = new Date(dateString);
        const dayIndex = date.getDay();

        return daysOfWeek[dayIndex];
    }

    return (
        <AlternatingListItem index={index}>
            <ListItemText>
                <Grid container direction='row' alignItems='center'>
                    <Grid item container direction="column" xs={4}>
                        <Grid item xs={12}><Typography align="left">{data.date}</Typography></Grid>
                        <Grid item xs={12}><Typography variant='caption' align="left">{getDayOfWeekSwedish(data.date)}</Typography></Grid>
                    </Grid>
                    <Grid item xs={4}><Typography align="center">{data.impressions?.toLocaleString('sv-SE')}</Typography></Grid>
                    <Grid item xs={4}><Typography align="center">{data.clicks?.toLocaleString('sv-SE')}</Typography></Grid>
                </Grid>
            </ListItemText>
        </AlternatingListItem>
    )
}