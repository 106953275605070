import React from 'react';
import { DefaultPage } from '../../DefaultComponents';
import { TitleGrid } from './TitleGrid';
import { AdNumberGrid } from './AdNumberGrid';
import { TargetingGrid } from './TargetingGrid';
import { InteractionsGrid } from './InteractionsGrid';
import { MaterialGrid } from './MaterialGrid';
import { NadirNotes } from '../NadirNotes';

export const Display = ({ item, updateLineitem, isInternal }) => {
    return (
        <DefaultPage>
            <TitleGrid item={item} updateLineitem={updateLineitem} isInternal={isInternal} />
            <AdNumberGrid item={item} />
            <NadirNotes id={item?.id} itemNote={item?.note} />
            <InteractionsGrid item={item} />
            <TargetingGrid item={item} />
            <MaterialGrid item={item} />
        </DefaultPage>
    )
};
