import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Card, CardContent, CardHeader, Grid, Paper, Tooltip, Typography } from '@mui/material';
import { blueGrey, grey } from '@mui/material/colors';
import { React } from 'react';
import { useNavigate } from 'react-router-dom';

const convertToArray = c => (Array.isArray(c) ? c : [c]);

export const DefaultPage = props => {
    return (
        <Grid container justifyContent='center'>
            <Grid item xs={12}>
                <Grid container spacing={2} direction='column'>
                    {props?.children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const DefaultBody = props => {
    const { paperSx } = props;
    return (
        <Grid item xs>
            <Paper elevation={0} sx={{ ...paperSx, p: 2 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container spacing={2} justifyContent='center'>
                        {props?.children}
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    );
};

export const PaperBody = props => {
    const { paperSx } = props;
    return (
        <Grid item xs>
            <Paper elevation={0} sx={{ ...paperSx, p: 2 }}>
                {props?.children}
            </Paper>
        </Grid>
    );
};

export const DefaultBodyHeader = props => {
    return (
        <Grid item container spacing={2} justifyContent='space-between' sx={{ mb: 1 }}>
            {convertToArray(props?.children)?.map((child, i) => {
                return (
                    <Grid item key={i}>
                        {child}
                    </Grid>
                );
            })}
        </Grid>
    );
};

export const HeaderWithChildren = props => {
    return (
        <Grid item xs>
            <Paper elevation={0} sx={{ background: '#0E3C64', p: 2 }}>
                <Grid item container spacing={1} justifyContent='space-between'>
                    {convertToArray(props?.children).map((child, i) => {
                        return (
                            <Grid item key={i}>
                                {child}
                            </Grid>
                        );
                    })}
                </Grid>
            </Paper>
        </Grid>
    );
};

export const HeaderWithText = props => {
    const { text1, text2 } = props;
    return (
        <Grid item xs>
            <Paper elevation={0} sx={{ background: '#0E3C64', p: 2 }}>
                <Typography variant='h5' color='white'>
                    {text1}
                </Typography>
                <Typography variant='p' color={blueGrey[300]}>
                    {text2}
                </Typography>
            </Paper>
        </Grid>
    );
};

export const GroupParentPage = props => {
    const { info } = { ...props }

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description} />
            <Grid item container justifyContent='flex-start' spacing={2}>
                {info.subRoutes.map(c => (
                    <MediaCard key={c.text + c?.group} item={c}></MediaCard>
                ))}
            </Grid>
        </DefaultPage>
    );
};

const MediaCard = props => {
    const item = props?.item;
    const navigate = useNavigate();
    return (
        <Grid item xs={12} sm={6} lg={3}>
            <Card elevation={0} sx={{ height: '100%', cursor: 'pointer' }} onClick={() => navigate(item.route)}>
                <CardContent>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        <Grid item>
                            <Typography variant='h6'>{item?.text}</Typography>
                            <Typography variant='subtitle2' color='text.secondary'>
                                {item.group}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ArrowForwardIcon sx={{ mt: 1, color: 'text.secondary' }} />
                        </Grid>
                    </Grid>
                    <Typography variant='body2' mt={1}>
                        {item?.description}
                    </Typography>
                </CardContent>
            </Card>
        </Grid>
    );
};

export const DefaultCard = props => {
    return (
        <Card variant='outlined' sx={{ textAlign: 'center', border: 'none' }}>
            {props?.title && <CardHeader title={props.title} sx={{ textTransform: 'uppercase', color: grey[600] }} />}
            <CardContent>{props?.children}</CardContent>
        </Card>
    );
};

export const DefaultTooltip = props => {
    return (
        <Tooltip
            title={props.title}
            placement={props?.placement ?? 'top'}
            arrow
            disableInteractive
        >
            {props?.children}
        </Tooltip>
    )
}