import { Box, Divider, Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { formatDate } from '../../../functions/misc';
import { DefaultCard, PaperBody } from '../../DefaultComponents';

export const AdNumberGrid = ({ item }) => {
    const contacts = Math.round(item?.delivery?.deliveredContacts)?.toLocaleString('sv-SE') ?? 0;
    const views = Math.round(item?.delivery?.deliveredNumberOfViews)?.toLocaleString('sv-SE') ?? 0;
    const sov = item?.delivery?.deliveredShareOfVoice?.toFixed(2)?.toLocaleString('sv-SE') ?? 0;
    const bookedSov = item?.delivery?.bookedShareOfVoice?.toFixed(2)?.toLocaleString('sv-SE') ?? 0;

    return (
        <Grid container item xs>
            <PaperBody>
                <Typography variant='h5' sx={{ color: grey[600] }}>
                    {item?.name ?? 'Kampanjnamn saknas'}
                </Typography>
                <Typography variant='subtitle1' sx={{ mb: 2 }}>
                    {formatDate(item.start)} - {formatDate(item.end)}
                </Typography>
                <Grid container spacing={2} justifyContent='space-evenly'>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DefaultCard title='Kontakter'>
                            <Typography variant='h3'>{contacts}</Typography>
                        </DefaultCard>
                    </Grid>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                        <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                    </Box>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DefaultCard title='Visningar'>
                            <Typography variant='h3'>{views}</Typography>
                        </DefaultCard>
                    </Grid>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'none', lg: 'block' } }}>
                        <Divider orientation='vertical' variant='middle' sx={{ height: '95%' }} />
                    </Box>
                    <Grid item xs={12} sm={6} lg={3}>
                        <DefaultCard title='SOV'>
                            <Typography variant='h3'>{sov}%</Typography>
                            <Typography sx={{ color: grey[600] }}>{bookedSov}% SOV bokat</Typography>
                        </DefaultCard>
                    </Grid>
                </Grid>
            </PaperBody>
        </Grid>
    )
}