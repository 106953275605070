import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Typography, Select, MenuItem, FormControl, InputLabel, Chip, Stack } from '@mui/material';
import { getBackendSubDomain } from '../../functions/subDomain';
import SearchIcon from '@mui/icons-material/Search';
import { getPercentColor, timeUnitConvert } from '../../functions/misc';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ProgressBar, SingleDatePicker } from '../forecast/components';
import LeafletMap from './LeafComponent';
import { useMobile } from '../../material/theme';

export const DoohScreen = () => {
    const location = useLocation();
    const { id } = useParams();
    const isMobile = useMobile();
    const navigate = useNavigate();
    const [screen, setScreen] = useState(location.state?.screen ?? null);

    useEffect(() => {
        async function getData(id) {
            const screenData = await fetch(`${getBackendSubDomain('nadir')}/doohclick/screen/${id}`)
                .then(e => e.json())
            console.log(screenData);
            setScreen(screenData);
        }
        !screen && getData(id);
    }, []);

    return (
        <Grid container justifyContent='center' spacing={2}>
            <Grid item xs={12}>
                {screen && (
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper elevation={0} sx={{ p: 2 }}>
                                <Grid container spacing={2}>
                                    {screen?.screenTenant?.azureUrl && (
                                        <Grid container item xs={12} md={6} justifyContent='center'>
                                            <img
                                                src={screen?.screenTenant?.azureUrl}
                                                alt={screen?.screenName}
                                                style={{ marginBottom: 'auto', display: 'block', maxWidth: '100%', maxHeight: isMobile ? 500 : '100%' }}
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs>
                                        <Stack spacing={0.5}>
                                            <Typography gutterBottom variant='h5' component='div'>
                                                {screen?.screenName}
                                            </Typography>
                                            <Typography gutterBottom color='text.secondary'>
                                                {screen?.screenTenant?.longDescription}
                                            </Typography>
                                            <TitleAndText title='Ägare' text={screen?.ownerName} />
                                            <TitleAndText title='Tid' text={`${screen?.screen?.openTime} - ${screen?.screen?.closeTime}`} />
                                            <TitleAndText
                                                title='Upplösning'
                                                text={`${screen?.ruleSet?.defaultResWidth}x${screen?.ruleSet?.defaultResHeight}`}
                                            />
                                            <TitleAndText title='Storlek' text={screen?.screenTenant?.landArea} />
                                            {(screen?.extra?.screen?.latitude !== 0 && screen?.extra?.screen?.longitude !== 0) && (
                                                <LeafletMap
                                                    markers={[
                                                        {
                                                            latitude: screen?.extra?.screen?.latitude,
                                                            longitude: screen?.extra?.screen?.longitude,
                                                            name: screen?.screenName,
                                                        },
                                                    ]}
                                                    center={{ latitude: screen?.extra?.screen?.latitude, longitude: screen?.extra?.screen?.longitude }}
                                                    zoom={12}
                                                />
                                            )}
                                        </Stack>
                                        {/* <Button variant='contained' sx={{ mt: 2 }} onClick={() =>
                                            navigate(`/dooh/skylt/${screen?.screenTenant?.id}/preview`, {
                                                state: {
                                                    screen: screen,
                                                },
                                            })
                                        }>Preview</Button> */}
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                        <ForecastScreen screenId={screen?.screen?.id} />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};

const ForecastScreen = props => {
    const { screenId } = props;
    const [loading, setLoading] = useState(false);
    const [forecastData, setForecastData] = useState(null);
    const [timeUnit, setTimeUnit] = useState(3);
    const [date, setDate] = useState(new Date());

    async function getData() {
        setLoading(true);
        const forecast = await fetch(`${getBackendSubDomain('krv')}/dooh/forecast/screenreport?screenid=${screenId}&startdate=${date?.toISOString()}&type=${timeUnit}`)
            .then(e => e.json())
            .then(e => e?.items);
        setForecastData(forecast);
        setLoading(false);
    }
    return (
        <Grid item xs={12}>
            <Paper elevation={0} sx={{ p: 2 }}>
                <Grid container direction='column' spacing={2}>
                    <Grid item container spacing={2} justifyContent='center'>
                        <Grid item xs={6} sm={3} md={4}>
                            <FormControl variant='outlined' size='medium' fullWidth>
                                <InputLabel>Tidsenhet</InputLabel>
                                <Select
                                    label='Tidsenhet'
                                    value={timeUnit}
                                    onChange={value => {
                                        setForecastData(null);
                                        setTimeUnit(value.target.value);
                                    }}
                                >
                                    <MenuItem value={3}>Dagar</MenuItem>
                                    <MenuItem value={2}>Veckor</MenuItem>
                                    <MenuItem value={1}>Månader</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} sm={3} md={4}>
                            <SingleDatePicker
                                setDate={date => {
                                    setForecastData(null);
                                    setDate(date);
                                }}
                                date={date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                endIcon={<SearchIcon />}
                                fullWidth
                                size='large'
                                variant='contained'
                                sx={{ height: '56px' }}
                                color='primary'
                                disableElevation
                                disabled={loading}
                                onClick={() => getData()}
                            >
                                {loading ? 'Laddar...' : 'Kontrollera lager'}
                            </Button>
                        </Grid>
                    </Grid>
                    {forecastData && (
                        <Grid item container>
                            <Grid item container direction='column' xs={3} sx={{ px: 1 }}>
                                <Grid item container sx={{ height: 55 }}>
                                    <Grid item>
                                        <Typography variant='h6' sx={{ lineHeight: 1.2 }}>
                                            Ledigt lager
                                        </Typography>
                                    </Grid>
                                </Grid>
                                {forecastData?.map(entry => {
                                    let name = entry.name;
                                    if (timeUnit === 3) {
                                        const nameSplit = entry.name.split('.');
                                        name = `${nameSplit[2]}-${nameSplit[1]}-${nameSplit[0]}`;
                                    } else name = timeUnitConvert(name);
                                    return (
                                        <Grid item container key={name} sx={{ height: 55 }} alignItems='center'>
                                            <Grid item>
                                                <Typography maxWidth='180px' title={name} noWrap>
                                                    {name}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                            <Grid item container direction='column' xs sx={{ px: 1 }}>
                                <Grid item sx={{ height: 55 }} />
                                {forecastData?.map(entry => {
                                    const percentage = 100 - (entry?.percentageNumber ?? 0);
                                    return (
                                        <Grid item key={entry.name} sx={{ height: 55 }}>
                                            <ProgressBar
                                                value={percentage}
                                                color={getPercentColor(percentage)}
                                                width='70%'
                                                tooltipText={<Typography variant='caption'>{percentage.toFixed(2)}%</Typography>}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Paper>
        </Grid>
    );
};

const TitleAndText = ({ title, text }) => {
    if (text) {
        return (
            <Typography variant='body1'>
                {title}: {text}
            </Typography>
        );
    } else return <></>;
};
