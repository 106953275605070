import { useEffect, useState } from 'react';
import { getBackendSubDomain } from '../../subDomain';
import { formatAudienceTargeting, formatCustomTargeting } from '../../misc';

export const useTimeOverviewForecast = request => {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const fetchOverview = () => {
        setLoading(true);

        const payload = {
            placements: request.settings.placement,
            time_quantity: request.settings.timeQuantity,
            time_unit: request.settings.timeUnit,
            custom_targeting: formatCustomTargeting(request.settings.customTargeting),
            audience_targeting: formatAudienceTargeting(request.settings.audienceTargeting)
        }

        fetch(`${getBackendSubDomain('krv')}/gam/forecast/time_overview`, {
            headers: { 'content-type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(payload),
        })
            .then(res => res.json())
            .then(data => setData(data))
            .catch(err => console.warn(err))
            .finally(() => setLoading(false));
    };

    const resetData = () => setData(null);

    useEffect(() => {
        //Check ok values
        if (request) fetchOverview();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [request]);

    return [data, loading, resetData];
};