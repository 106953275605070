import { Box, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import React from 'react';
import { PaperBody } from '../../DefaultComponents';
import { CustomInfoIcon } from '../../InfoIcon';
import { Creative } from './Creative';
import { sumArrayInteraction } from './displayFunction';

export const MaterialGrid = (props) => {
    const { item } = props

    const [creativeSorting, setCreativeSorting] = React.useState('impressionsDelivered');

    const sortCreatives = (creatives) => {
        let direction = 1;
        creatives.sort((a, b) => {
            if (creativeSorting === 'impressionsDelivered' || creativeSorting === 'clicksDelivered') {
                direction = -1;
                a = a?.stats?.[creativeSorting] ?? 0;
                b = b?.stats?.[creativeSorting] ?? 0;
            } else if (creativeSorting === 'ctr') {
                direction = -1;
                a = a?.stats?.clicksDelivered / a?.stats?.impressionsDelivered ?? 0;
                b = b?.stats?.clicksDelivered / b?.stats?.impressionsDelivered ?? 0;
            } else if (creativeSorting === 'height' || creativeSorting === 'width') {
                direction = -1;
                a = a?.size?.[creativeSorting] ?? 0;
                b = b?.size?.[creativeSorting] ?? 0;
            } else if (creativeSorting === 'start' || creativeSorting === 'end') {
                if (creativeSorting === 'end') direction = -1;
                a = a?.[creativeSorting] ?? item?.[creativeSorting];
                b = b?.[creativeSorting] ?? item?.[creativeSorting];
            }
            else {
                a = a[creativeSorting];
                b = b[creativeSorting];
            }
            if (a > b) return direction;
            if (a < b) return -direction;
            return 0;
        });
        return creatives;
    }

    return (
        <PaperBody>
            <Grid item container spacing={1.5} justifyContent='space-between' mb={4}>
                <Grid item container xs={12} sm={8} md={9} xl={10} spacing={2}>
                    <Grid item>
                        <Typography variant='h5' sx={{ color: grey[600] }}>
                            Annonsmaterial
                        </Typography>
                    </Grid>
                    <Grid item mt={0.5}>
                        <CustomInfoIcon
                            text='Observera att adservern uppdaterar statistik för annonsmaterial mer frekvent än för kampanjen i sin helhet, 
                                det kan därför vara så att annonsmaterialet ibland visar ett större antal visningar eller klick än kampanjen'
                        />
                    </Grid>
                </Grid>
                {item?.creatives?.length > 1 && (
                    <Grid item container xs={6} sm={4} md={3} xl={2} justifyContent='flex-end' mt={0.5}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='creative-sorting-label'>Sortera på</InputLabel>
                            <Select
                                labelId='creative-sorting-label'
                                id='creative-sorting'
                                value={creativeSorting}
                                label='Sortera på'
                                onChange={e => setCreativeSorting(e.target.value)}
                            >
                                <MenuItem value={'impressionsDelivered'}>Visningar</MenuItem>
                                <MenuItem value={'clicksDelivered'}>Klick</MenuItem>
                                <MenuItem value={'ctr'}>CTR</MenuItem>
                                <MenuItem value={'status'}>Status</MenuItem>
                                <MenuItem value={'start'}>Startdatum</MenuItem>
                                <MenuItem value={'end'}>Slutdatum</MenuItem>
                                <MenuItem value={'height'}>Storlek: höjd</MenuItem>
                                <MenuItem value={'width'}>Storlek: bredd</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                )}
            </Grid>
            <Grid item xs>
                {item?.creatives?.length > 0 ? (
                    <Grid container direction='column'>
                        {sortCreatives(item?.creatives)?.map((creative, i) => {
                            return (
                                <React.Fragment key={creative.id}>
                                    <Creative creative={creative} lineitem={item} sumArrayInteraction={sumArrayInteraction} />
                                    {i < item?.creatives?.length - 1 ? <Divider sx={{ my: 5 }} /> : <Box sx={{ pb: 2 }}></Box>}
                                </React.Fragment>
                            )
                        })}
                    </Grid>
                ) : (
                    <Grid container justifyContent='center' mb={2} textAlign='center'>
                        <Typography variant='h6'>Kampanjen saknar annonsmaterial</Typography>
                    </Grid>
                )}
            </Grid>
        </PaperBody>
    )
}