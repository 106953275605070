import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import { DefaultBody, DefaultPage, HeaderWithText } from '../DefaultComponents';
import { flattenedLayout } from '../../config/navigation';
import React from 'react';

export const CpmTool = props => {
    const info = flattenedLayout.toolCpm;

    const [data, setData] = React.useState({ cost: null, cpm: null, impressions: null });
    const [mode, setMode] = React.useState('impressions');

    const switchMode = v => {
        setMode(v);
        setData({ cost: null, cpm: null, impressions: null });
    };

    //För att bara sätta värde på fält
    // const handleInputChange = (k, v) => setData(prevData => ({ ...prevData, [k]: v }));
    const handleInputChange = (key, v) => {
        let cost = data.cost;
        let cpm = data.cpm;
        let impressions = data.impressions;

        if (key === 'cost') cost = v;
        else if (key === 'cpm') cpm = v;
        else if (key === 'impressions') impressions = v;

        switch (mode) {
            case 'cost':
                if (cpm && impressions) cost = impressions * (cpm / 1000);
                break;
            case 'cpm':
                if (cost && impressions) cpm = cost / (impressions / 1000);
                break;
            case 'impressions':
                if (cpm && cost) impressions = cost / (cpm / 1000);
                break;
            default:
                void 0;
                break;
        }

        setData({ cost, cpm, impressions });
    };

    //Lösning för ingen radioGrp
    // const handleInputChange = (k, v) => {
    //     let cost = data.cost;
    //     let cpm = data.cpm;
    //     let impressions = data.impressions;

    //     if (k === 'cost') {
    //         cost = v;
    //         if (cpm) {
    //             impressions = cost / (cpm / 1000);
    //         } else if (impressions) {
    //             cpm = cost / (impressions / 1000);
    //         }
    //     } else if (k === 'cpm') {
    //         cpm = v;
    //         if (cost) {
    //             impressions = cost / (cpm / 1000);
    //         } else if (impressions) {
    //             cost = impressions * (cpm / 1000);
    //         }
    //     } else if (k === 'impressions') {
    //         impressions = v;
    //         if (cost) {
    //             cpm = cost / (impressions / 1000);
    //         }
    //         else if (cpm) {
    //             console.log("this cpm", cpm);
    //             cost = impressions * (cpm / 1000);
    //         }
    //     }

    //     setData({ cost, cpm, impressions });
    // };

    return (
        <DefaultPage>
            <HeaderWithText text1={info.text} text2={info.description}></HeaderWithText>
            <DefaultBody>
                <Grid item xs={12} sm={12} md={12}>
                    <SelectModeRadio value={mode} setValue={switchMode} />
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <NumberField label='Budget' value={data.cost} setValue={value => handleInputChange('cost', value)} disabled={mode === 'cost'}></NumberField>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <NumberField label='CPM' value={data.cpm} setValue={value => handleInputChange('cpm', value)} disabled={mode === 'cpm'}></NumberField>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <NumberField
                        label='Visningar'
                        value={data.impressions}
                        setValue={value => handleInputChange('impressions', value)}
                        disabled={mode === 'impressions'}
                    ></NumberField>
                </Grid>
            </DefaultBody>
        </DefaultPage>
    );
};

export const NumberField = props => {
    const { label, value, setValue, disabled } = props;
    return (
        <TextField
            fullWidth
            label={label}
            type='number'
            disabled={disabled}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={e => {
                setValue(e.target.value);
            }}
            variant='outlined'
            value={value ?? ''}
        />
    );
};

const SelectModeRadio = props => {
    const { value, setValue } = props;
    return (
        <FormControl>
            <FormLabel id="radio">Beräkna fält:</FormLabel>
            <RadioGroup row name='filter-radio-buttons-group' value={value} onChange={event => setValue(event.target.value)}>
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='cost' control={<Radio size='small' color='accent' />} label='Budget' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='cpm' control={<Radio size='small' color='accent' />} label='CPM' />
                <FormControlLabel sx={{ fontSize: '0.9rem' }} value='impressions' control={<Radio size='small' color='accent' />} label='Visningar' />
            </RadioGroup>
        </FormControl>
    );
};
