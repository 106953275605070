import jsondata from './placements.json';

export const customOrderSortObj = (a, b, sortOrder, sortBy) => {
    if (sortOrder[a[sortBy]] && sortOrder[b[sortBy]]) return sortOrder[a[sortBy]] - sortOrder[b[sortBy]];
    else if (sortOrder[a[sortBy]]) return -1;
    else if (sortOrder[b[sortBy]]) return 1;
    else return a[sortBy].localeCompare(b[sortBy]);
};
export const customOrderSortArr = (a, b, sortOrder) => {
    if (sortOrder[a] && sortOrder[b]) return sortOrder[a] - sortOrder[b];
    else if (sortOrder[a]) return -1;
    else if (sortOrder[b]) return 1;
    else return a.localeCompare(b);
};

const blockedStrings = [
    'aura',
    'framtids',
    'midscroll',
    'topscroll',
    'native',
    'matchads',
    'fill rate',
    'livewrapped',
    'special',
    'veckans',
    'minlunch',
    'test-inno',
    'innovation',
];

export const formatFilterOptions = [
    'Display Mix',
    'Panorama Mix',
    'Takeover Mix',
    'Topscroll Mix',
    'Mobile',
    'Mobile Takeover',
    'Mobile Topscroll',
    'Desktop Panorama',
    'Desktop Insider',
    'Desktop Modul',
    'Desktop Outsider',
    'Desktop Takeover',
    'Desktop Topscroll',
    'Preroll',
    'Aura Nyheter',
    'Aura Jobb'
];

export const sitePlacements = jsondata.filter(p => blockedStrings.every(filter => !p.name.toLowerCase().includes(filter)));

export const getPlacementsByPlacementName = (placementName) => sitePlacements.filter((p ) => p.name.endsWith(placementName))

export const placementSortValues = {
    'Total': 1,
    'Display Mix': 2,
    'Takeover Mix': 3,
    'Panorama Mix': 4,
    'Mobile': 5,
    'Mobile Takeover': 6,
    'Desktop Panorama': 7,
    'Desktop Insider': 8,
    'Desktop Modul': 9,
    'Desktop Outsider': 10,
    'Desktop Takeover': 11,
};
export const siteSortValues = { Total: 1 };

export const placements = [...new Set(sitePlacements.map(p => p.name.split(' ').slice(1).join(' ')))].sort((a, b) =>
    customOrderSortArr(a, b, placementSortValues)
);

export const sites = [...new Set(sitePlacements.map(p => p.name.split(' ')[0]))].sort();
export const getSitePlacementsFilter = site => sitePlacements.filter(placement => site === placement.name.split(' ')[0]);

export const getSitesPlacementsFilter = (siteArr, placementArr, onlyId = false) => {
    let arr = sitePlacements;
    if (siteArr.length > 0) arr = arr.filter(p => siteArr.includes(p.name.split(' ')[0]));
    if (placementArr.length > 0) arr = arr.filter(p => placementArr.includes(p.name.split(' ').slice(1).join(' ')));
    return onlyId ? arr.map(p => p.id) : arr;
}

export const translatePlacement = {
    'Mobile': 'Panorama (mobil)',
    'Mobile Takeover': 'Helsida (mobil)',
    'Desktop Panorama': 'Panorama (desktop)',
    'Desktop Insider': 'Insider (desktop)',
    'Desktop Modul': 'Modul (desktop)',
    'Desktop Outsider': 'Outsider (desktop)',
    'Desktop Takeover': 'Helsida (desktop)',
    'Takeover Mix': 'Helsida Mix',
};
